.camera-input {
    border: 1px dashed var(--blue);
    padding: 10% 3% 3%;
    border-radius: 5%;
    line-height: 2;
    height: 260px;
    background-color: var(--color-primaryFade);
}

.preview {
    width: 260px;
    height: 260px;
}