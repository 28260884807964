.error-items {
  font-family: var(--f400-secondary);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--red);
  padding: 15px 0px;
  border-radius: 50px;
  background: var(--red-opacity-15);
  margin-top: 20px !important;
  margin-bottom: 20px;
  text-align: center;
  width: 200px !important;
}

.items {
  padding-top: 30px;
  padding-bottom: 100px;
  background: var(--bg-other-main);
}

.items__content {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

.items__left {
  flex: 1 1 0;
  width: 100%;
}

.items__left_wrapper {
  margin: -15px;
  display: flex;
  flex-wrap: wrap;
}

.items__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  border: 3px solid transparent;
  padding: 30px 30px 10px 30px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  max-width: calc(100% / 3 - 30px);
  width: 100%;
  margin: 15px;
  background: white;
}

.card__target {
  width: 35px;
  height: 35px;
  background: var(--blue);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  top: -3px;
  left: -3px;
  display: none;
}

.items__card.active .card__target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.items__card.active {
  border: 3px solid var(--blue);
}

.card__icon {
  fill: var(--white);
  width: 17px;
  height: 13px;
}

.card__pic {
  max-width: 140px;
  width: 100%;
  height: 140px;
  margin-right: 20px;
}

.card__img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.card__info {
  padding-top: 7px;
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.card__select {
  min-width: 45px;
}

.card__select__container {
  width: 100%;
}

.card__select__container__item {
}

.card__title {
  font-family: var(--f700-primary);
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--blue);
  margin-bottom: 8px;
}

.card__br {
  display: block;
}

.card__new_price {
  font-family: var(--f800-secondary);
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  color: var(--blue);
}

.card__old_price {
  font-family: var(--f400-secondary);
  font-weight: 400;
  color: var(--purple);
  font-size: 16px;
  line-height: 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-bottom: 8px;
}

.card__old_price::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--purple);
  top: 60%;
  left: 0;
}

.info__error {
  font-family: var(--f400-secondary);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--red);
  padding: 5px 10px;
  border-radius: 50px;
  background: var(--red-opacity-15);
  margin-top: auto;
  text-align: center;
}

@media (max-width: 1399px) {
  .items__right {
    flex: 1 1 0;
  }

  .items__card {
    max-width: calc(100% / 2 - 30px);
    border-radius: 6px;
  }
}

@media (max-width: 991px) {
  .items__content {
    align-items: center;
  }

  .items__left_wrapper {
    margin-bottom: 30px;
  }

  .items {
    padding-top: 45px;
  }

  .items__content {
    flex-direction: column;
  }

  .items__card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: calc(50% - 30px);
  }

  .card__info {
    width: 100%;
  }

  .card__title {
    text-align: center;
  }

  .card__info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .card__pic {
    margin-right: 0;
    margin-bottom: 26px;
  }
}

@media (max-width: 575px) {
  .items__card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 18px 24px 17px;
    flex: 1 1 100%;
    max-width: 100%;
    margin: 0 25px;
  }

  .card__target {
    width: 25px;
    height: 25px;
  }

  .card__icon {
    fill: var(--white);
    width: 14px;
    height: 10px;
  }

  .card__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 9px;
  }

  .card__old_price {
    display: none;
  }

  .card__new_price {
    font-size: 16px;
    line-height: 24px;
  }

  .items__card:not(.show) {
    display: none;
  }
}

@media (max-width: 374px) {
  .col-ltl-12 {
    width: 75%;
  }
}
