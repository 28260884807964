 .p13__row {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     width: 100%;
     height: 100%;
     padding-bottom: 200px;
 }

 .p13__content {
     width: 50%;
     height: 100%
 }

 .p13__left {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     max-width: 370px;
     width: 100%;
     padding-top: 22px;
     padding-bottom: 16px;
     position: relative;
     z-index: 1
 }



 .p13__title {
     text-align: left;
     padding-left: 0;
     padding-right: 0;
     font-family: var(--f700-primary);
     font-weight: 700;
     font-size: 40px;
     line-height: 60px;
     color: var(--blue);
 }

 .p13__desc {
     font-family: var(--f400-secondary);
     font-weight: 400;
     font-size: 18px;
     line-height: 30px;
     color: var(--purple);
     margin-bottom: 62px
 }

 .p13__right {
     background: var(--purple);
     border-radius: 10px;
     position: relative;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: reverse;
     -ms-flex-direction: column-reverse;
     flex-direction: column-reverse;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     padding: 50px 0;
     overflow: hidden;
     max-width: 370px;
     width: 100%;
     height: 360px;
     z-index: 1
 }


 .p13__right_title {
     font-family: var(--f700-primary);
     font-weight: 700;
     font-size: 30px;
     line-height: 30px;
     color: var(--white);
     margin-top: 35px;
     margin-bottom: 0;
 }

 .p13__right_circle {
     /* min-width: 200px; */
     width:200px;
     height: 200px;
     margin:0 auto;
     text-align:center;
     border-radius: 50%;
     background: var(--white);
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
 }

 .p13__right_price {
     font-family: var(--f700-secondary);
     font-weight: 700;
     font-size: 40px;
     line-height: 40px;
     color: var(--purple)
 }

 .p13__right_action {
     font-family: var(--f400-secondary);
     font-weight: 400;
     font-size: 18px;
     line-height: 30px;
     width:80%;
     color: var(--purple)
 }

 .p13__right_img {
     opacity: 0.1;
     position: absolute;
     top: 55%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     z-index: -1
 }

 .p13__content.hidden {
     display: block
 }

 .mobile_show {
     display: none;
 }

 @media (max-width: 991px) {
     .p13__content {
         width: 100%;
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         -webkit-box-pack: center;
         -ms-flex-pack: center;
         justify-content: center;
         height: auto;
     }

     .mobile_show {
         display: block !important;
         margin-bottom: 60px;
         clear: both;

     }

     .mobile_hide {
         display: none !important;
     }

     .p13__left {
         padding-top: 0
     }

     .p13__title {
         padding-left: 30px;
         padding-right: 30px;
         text-align: center;
         margin-bottom: 30px;
         font-size: 30px !important;
     }

     .p13__desc {
         -webkit-box-ordinal-group: 3;
         -ms-flex-order: 2;
         order: 2;
         margin-bottom: 50px;
         font-size: 16px;
         line-height: 24px;
         text-align: center
     }

     .p13__rating {
         -webkit-box-ordinal-group: 4;
         -ms-flex-order: 3;
         order: 3;
         -webkit-box-orient: vertical;
         -webkit-box-direction: normal;
         -ms-flex-direction: column;
         flex-direction: column;
         margin-bottom: 28px
     }

     .p13 button {
         -webkit-box-ordinal-group: 5;
         -ms-flex-order: 4;
         order: 4
     }

     .p13__content.hidden {
         display: none
     }

     .p13__right {
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         padding: 20px;
         height: -webkit-fit-content;
         height: -moz-fit-content;
         height: fit-content;
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         -ms-flex-direction: row;
         flex-direction: row;
         -webkit-box-align: center;
         -ms-flex-align: center;
         align-items: center;
         -webkit-box-pack: justify;
         -ms-flex-pack: justify;
         justify-content: space-between;
         -webkit-box-ordinal-group: 2;
         -ms-flex-order: 1;
         order: 1;
         margin-bottom: 33px
     }

     .p13__right_circle {
         min-width: 90px;
         height: 90px;
     }

     .p13__right_title {
         font-size: 17px;
         margin-right: 0;
         margin-top: 0;
         margin-bottom: 0;
         line-height: 26px;
         max-width: 145px;
     }

     .p13__right_price {
         font-size: 24px
     }

     .p13__right_action {
         font-size: 14px;
         line-height: 20px
     }

     .p13__right_img {
         left: 30%
     }
 }

 @media (max-width: 767px) {}

 @media (max-width: 575px) {}