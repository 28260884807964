.tooltip_custom {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip_custom .tooltiptext_custom {
    visibility: hidden;
    width: max-content;
    background-color: var(--purple);
    color: var(--white);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 18px;
    right: 0;
    padding: 10px;
    margin-left: -60px;
}

.tooltip_custom:hover .tooltiptext_custom {
    visibility: visible;
    font-size: 14px;
}

.tooltip_custom .tooltiptext_custom::after {
    content: "";
    position: absolute;
    top: 95%;
    right: 28px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: var(--purple) transparent transparent transparent;

}