.p2 {
    padding-top: 88px;
    padding-bottom: 100px;
    background: var(--bg-other-main)
}

.p2__title {
    margin-bottom: 20px;
}

.p2__content {
    margin-bottom: 30px;
}

.global__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
}

.pagination-main .react-pagination-js-default>ul {
    display: flex !important;
}

.global__search-dropdown {
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    z-index: 15;
    background: var(--white);
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: var(--search-shadow);
    box-shadow: var(--search-shadow);
    display: none;
}

.global__search-dropdown.visible {
    display: block;
}

.global__search-list {
    padding: 0 5px;
    max-height: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    margin: 5px;
}

.global__search-list::-webkit-scrollbar {
    width: 5px;
}

.global__search-list::-webkit-scrollbar-thumb {
    width: 5px;
    background: var(--thumb-color);
    border-radius: 10px;
}

.global__search-item {
    padding: 10px;
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple);
    cursor: pointer;
    border-radius: 5px;
}

.global__search-item:hover {
    background: var(--search-hover);
}

.global__search-item.choose {
    background: var(--dark-purple-opacity-50);
    color: var(--blue)
}

.global__search {
    width: 100%;
    position: relative
}

.global__search-icon {
    fill: none;
    stroke: var(--purple);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 21px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.global__search-input {
    width: 100%;
    padding: 12px 55px;
    border-radius: 6px;
    border: none;
    background: var(--white);
    color: var(--purple)
}

.global__search-input:focus {
    outline: none
}

.global__search-input::-webkit-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple)
}

.global__search-input::-moz-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple)
}

.global__search-input:-ms-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple)
}

.global__search-input::-ms-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple)
}

.global__search-input::placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple)
}

.p2__row>* {
    margin-bottom: 30px;
}

.card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    border: 3px solid transparent;
    padding: 30px 30px 40px 30px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    cursor: pointer;
    max-width: calc(50% - 30px);
    width: 100%;
    height: 100%;
}

.card__target {
    width: 35px;
    height: 35px;
    background: var(--blue);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    position: absolute;
    top: -3px;
    left: -3px;
    display: none
}

.card.active .card__target {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.card.active {
    border: 3px solid var(--blue)
}

.card__icon {
    fill: var(--white);
    width: 17px;
    height: 13px
}

.card__pic {
    max-width: 140px;
    width: 100%;
    height: 140px;
    margin-right: 20px
}

.card__img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.card__info {
    padding-top: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.card__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--blue);
    margin-bottom: 8px;
    text-align: center;
}

.card__br {
    display: block
}

.card__new-price {
    font-family: var(--f800-secondary);
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: var(--blue)
}

.card__old-price {
    font-family: var(--f400-secondary);
    font-weight: 400;
    color: var(--purple);
    font-size: 16px;
    line-height: 24px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative
}

.card__old-price::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--purple);
    top: 60%;
    left: 0
}

.p2__card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    margin: 0;
    margin-bottom: 30px;
    padding: 10px 10px 57px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.p2__card:hover {
    -webkit-box-shadow: 0px 0px 50px rgba(159, 154, 221, 0.35);
    box-shadow: 0px 0px 50px rgba(159, 154, 221, 0.35);
}

.p2__card-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.p2__card .exchange__card-separator {
    display: none
}

.p2__card .card__pic {
    margin-right: 0 !important;
    margin-bottom: 26px !important;
    /* height:150px; */
    min-height: 200px !important;
}

.view-details {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: auto;
}

.view-out-of-stock {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
}

.error-items-padding {
    padding: 10px 5px 10px 5px !important
}

.page {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px
}

.global__pages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* width: 700px !important */
}

.prevArrow {
    border: 1px solid black;
    padding: 0px 10px;
}

.nextArrow {
    border: 1px solid black;
    padding: 0px 10px;
}

.arrowDisable {
    border: 1px solid grey;
}

.btn-align {
    margin-top: -4% !important;
}

.pageNo {
    padding: 2px 10px;
}

.react-pagination-js-default {
    /* width: 100% !important; */
    display: flex !important;
}

.pagination-main {
    width: 700px;
    display: flex;
}

.react-pagination-js-default .is-active {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 5px !important;
    margin-left: 5px !important;
    border-radius: 0px !important;
    border: none !important;
    background: #ffffff !important;
    box-shadow: none !important;
    font-weight: bold;
    text-decoration: none;
    color: var(--purple) !important;
    text-shadow: none !important;
    cursor: pointer;
}

.react-pagination-js-default .page {
    width: 30px;
    height: 30px;
    display: inline-block;
    padding: 0px 9px;
    margin-right: 5px !important;
    margin-left: 5px !important;
    border-radius: none !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    font-weight: bold;
    text-decoration: none !important;
    color: var(--purple) !important;
    text-shadow: none !important;
    cursor: pointer;
    font-size: 16px;
}

.global__pages-num {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px
}

.global__pages-link {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--purple);
    border-bottom: 1px solid var(--purple)
}

.global__pages-num.curent__page {
    background: var(--white)
}

.curent__page .global__pages-link,
.page__points .global__pages-link {
    border-bottom: none
}

.page__points {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--purple);
    cursor: default
}

.p2__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.p2 .btns {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 1199px) {
    .p2 .global__pages {
        margin-bottom: 30px;
    }
}

@media (max-width: 991px) {
    .p2 .global__pages {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
    .p2 .btns {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .btn__back {
        display: none !important;
    }

    .p2__card {
        display: flex !important;
    }
}