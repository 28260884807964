.p12__row_rm {
    margin-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.p12_rm .choose__card_rm {
    max-width: 350px;
    width: 100%;
    height: auto;
    padding: 36px 40px 40px;
    background: var(--reason-input-bg);
    border-radius: 6px;
    border: 1px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 15px 30px;
    position: relative
}

.p12_rm .choose__card_rm.active {
    border: 2px solid var(--blue);
    padding: 35px 39px 39px 39px
}

.choose__card_rm.active .choose__input_circle::after {
    background: var(--blue);
}



.p12_rm .choose__card_title_rm {
    margin-bottom: 5px;
    height: 100%
}

.p12__label_rm {
    width: 100%;
    text-align: center;
    cursor: pointer
}

.p12_rm .choose__text_rm {
    margin-bottom: 32px
}

.p12_rm .choose__text_rm {
    position: relative;
    padding-left: 30px;
    height: 100%
}

.radio_options {
    padding: 30px 0 0 0
}

.p12_rm .choose__card_title_rm {
    height: auto;
    margin-bottom: 31px
}

.p12__icon {
    fill: var(--purple);
    width: 30px;
    height: 25px;
    margin-right: 25px;
    position: absolute;
    top: 5px;
    left: 0
}

@media (max-width: 1199px) {

    .p12_rm .choose__card_title_rm {
        margin-bottom: 22px
    }
}


@media (max-width: 991px) {

    .p12_rm .choose__card_rm {
        max-width: 100%;
        padding: 24px 30px 24px 70px
    }

    .p12_rm .choose__card_rm.active {
        padding: 23px 29px 23px 69px
    }

    .p12__label_rm {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        top: 27px;
        left: 25px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }

    .choose__card_rm.active .p12__label_rm {
        top: 26px;
        left: 24px
    }
}

@media (max-width: 767px) {}

@media (max-width: 575px) {}