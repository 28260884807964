.bank-account__page {
    background: var(--blue);
    position: relative;
    overflow: hidden
}

.bank-account__row {
    padding-top: 21px;
    padding-bottom: 21px
}

.bank-account__form {
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background: var(--white);
    padding: 45px 90px 65px;
    position: relative;
    z-index: 5
}

.bank-account__bg-logo {
    position: absolute;
    fill: var(--white);
    height: 145%;
    width: 150%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1
}

.bg-logo__left {
    left: 75%
}

.bg-logo__right {
    right: 75%
}

.bank-account__form-title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: var(--blue);
    margin-bottom: 30px
}

.bank-account__form-data {
    margin-bottom: 30px
}

.bank-account__form-inp {
    width: 100%;
    border-radius: 5px;
    background: var(--bg-other-main);
    padding: 18px 25px;
    outline: 1px solid var(--grey-2);
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--blue);
    margin-bottom: 15px;
    border: none;
}

.bank-account__form-inp:focus {
    outline: 1px solid var(--blue)
}

.bank-account__form-inp::-webkit-input-placeholder {
    color: var(--purple)
}

.bank-account__form-inp::-moz-placeholder {
    color: var(--purple)
}

.bank-account__form-inp:-ms-input-placeholder {
    color: var(--purple)
}

.bank-account__form-inp::-ms-input-placeholder {
    color: var(--purple)
}

.bank-account__form-inp::placeholder {
    color: var(--purple)
}

.bank-account__form-inp:last-child {
    margin-bottom: 0
}

.bank-account__form-btn {
    width: 100%;
    background: var(--blue);
    text-align: center;
    padding-top: 19px;
    padding-bottom: 19px;
    text-transform: uppercase;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    color: var(--white);
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.bank-account__form-btn:disabled {
    background: var(--purple)
}

.bank-account__form-label {
    width: 100%;
    position: relative
}


@media (max-width: 575px) {
    .bank-account__form {
        padding: 40px 23px 62px
    }

    .bank-account__form-title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px
    }
}