* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none
}


:root {
    /* Primary Color */
    --blue: #473E9E;

    --light-blue: #3291E9;
    --white: #fff;
    --black: #000;
    /* Secondary Color */
    --purple: #918BC5;
    --btn-secondary-bg: rgba(71, 62, 158, 0.14);
    --purple-opacity-30: rgba(145, 139, 197, 0.3);
    --purple-opacity-20: rgba(145, 139, 197, 0.2);
    --light-purple: #BCBFD8;
    --red: #EE2654;
    --red-opacity-15: rgba(238, 38, 85, 0.15);
    --pink: #ff819e;
    --yellow: #FAB011;
    --grey: #e7e5f4;
    --grey-2: #E4E3F7;
    --f700-primary: "Comfortaa-Bold";
    --f400-secondary: "Lato-Regular";
    --f500-secondary: "Lato-Medium";
    --f600-secondary: "Lato-Semibold";
    --f700-secondary: "Lato-Bold";
    --f800-secondary: "Lato-Heavy";
    --f900-secondary: "Lato-Black";
    --f700-btn: "Nexa-Bold";
    --f700-photo-title: "Roboto-Bold";
    --f400-nav: "Gilroy-Regular";
    --f500-nav: "Gilroy-Medium";
    --f700-nav: "Gilroy-Bold";
    --main-input-bg: #F2F1FF;
    --btn-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
    --bg-other-main: #F2F1FF;
    --reason-input-bg: #fff;
    --reason-input-border: #E7E5F4;
    --reason-input-circle: #DAD8EC;
    --loading-bg: #EDECFF
}

a,
a:hover {
    text-decoration: none
}

p,
ul {
    margin-bottom: 0
}

ol,
ul {
    padding-left: 0
}

body {
    min-width: 375px;
}

.header {
    box-shadow: 0px 4px 10px rgba(203, 204, 205, 0.2);
}

.header__top {
    background: var(--header-top);
}

.header__top-content {
    display: flex;
    justify-content: space-between;
}

.header__offer {
    display: flex;
}

.header__offer-link {
    font-family: var(--f500-nav);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark-grey);
    padding: 9px 20px;
    display: flex;
    align-items: center;
}

.header__offer-link:hover {
    background: var(--grey);
    color: var(--dark-grey);
    ;
}

.header__offer-link:first-child {
    margin-left: -20px;
}

.header__contact {
    display: flex;
}

.contact__type {
    display: flex;
    padding: 9px 20px;
    border-right: 1px solid var(--dark-grey-opacity-15);
}

.contact__social {
    display: flex;
    margin-right: -25px;
}

.contact__icon,
.contact__social-icon {
    width: 20px;
    height: 20px;
    fill: var(--grey-3);
    transition: fill 0.3s;
}

.contact__type:hover .contact__icon,
.contact__social-link:hover .contact__social-icon {
    fill: var(--blue);
    transform: scale(1.2);
}

.contact__icon {
    margin-right: 12px;
}

.contact__type:hover {
    background: var(--grey);
}

.contact__social-link {
    display: flex;
    align-items: center;
    padding: 9px 20px;
}

.contact__social-link:hover {
    background: var(--grey);
}

.contact__text {
    font-family: var(--f400-nav);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark-grey);
}

.header__bottom_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 36px;
    padding-bottom: 36px;
}

.nav__link {
    font-family: var(--f700-nav);
    font-weight: 700;
    font-size: 15px;
    line-height: 22.5px;
    color: var(--nav-link);
    text-transform: uppercase;
    margin-right: 40px;
}

.nav__link:last-child {
    margin-right: 0;
}

.nav__link:hover {
    color: var(--blue);
}

.header__personal {
    display: flex;
    align-items: center;
}

.personal__item {
    margin-right: 15px;
}

.personal__item:last-child {
    margin-right: 0;
    margin-right: -15px;
}

.persnal__link {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.persnal__link:hover {
    background: var(--grey);
}

.personal__icon {
    width: 20px;
    height: 20px;
    fill: none;
    stroke: var(--dark-grey);
}

.persnal__link:hover .personal__icon {
    stroke: var(--blue);
}

.personal__count {
    min-width: 17px;
    height: 17px;
    border-radius: 17px;
    background: var(--red);
    position: absolute;
    bottom: 50%;
    left: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--f700-photo-title);
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 3px 5px;
    color: var(--white);
}

.logo {
    max-width: 170px;
    display: inline-block;
}

.logo_img {
    width: 100%;
    object-fit: contain;
    max-height: 70px !important;
}

.burger_menu__btn {
    width: 40px;
    height: 40px;
    display: none;
    justify-content: center;
    align-items: center;
}

.burger_menu__icon {
    width: 24px;
    height: 16px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.burger_menu__line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #000;
    display: inline-block;
    transition: opacity 0.2s;
}

.burger_menu__icon::after,
.burger_menu__icon::before {
    background-color: #000;
    border-radius: 4px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: 50% 50% 0;
    transition: width .2s, transform .2s;
    width: 100%;
}

.burger_menu__icon::after {
    bottom: 0;
    width: 70%;
}

.burger_menu__icon::before {
    top: 0;
}

.menu__small_screen {
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    background: var(--white);
    max-width: 300px;
    width: 100%;
    z-index: 15;
    flex-direction: column;
    padding: 30px 16px;
    transition: left 0.2s;
    display: flex;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
}

.menu__small_screen::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -15;
}

.menu__small_screen.show {
    left: 0;
}

.menu__logo {
    width: 100%;
    border-bottom: 1px solid var(--grey-2);
    text-align: center;
    padding-bottom: 11px;
}

.menu__small_screen .header__nav {
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid var(--grey-2);
}

.menu__small_screen .nav__link {
    padding: 10px 20px;
    margin-right: 0;
    text-align: center;
}

.menu__small_screen .header__top-content {
    flex-direction: column;
}

.menu__small_screen .header__offer {
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid var(--grey-2);
    margin-bottom: 44px;
}

.menu__small_screen .header__offer-link {
    margin-left: 0;
    justify-content: center;
    font-family: var(--f700-nav);
    font-weight: 700;
    font-size: 15px;
    line-height: 22.5px;
    color: var(--nav-link);
    text-transform: uppercase;
}

.menu__small_screen .header__offer-link:hover {
    color: var(--blue);
    background: transparent;
}

.menu__small_screen .header__contact {
    flex-direction: column;
}

.menu__small_screen .contact__type {
    border-right: none;
    justify-content: center;
}

.menu__small_screen .contact__type:hover {
    background: transparent;
}

.menu__small_screen .contact__type:hover .contact__icon {
    transform: scale(1);
}

.menu__small_screen .contact__icon {
    fill: var(--blue);
}

.menu__small_screen .contact__social {
    justify-content: center;
    margin-right: 0;
}

.menu__small_screen .contact__social-link:hover {
    background: transparent;
}

.menu__small_screen .contact__social-link:hover .contact__social-icon {
    transform: scale(1);
    fill: var(--purple);
}

.menu__small_screen .contact__social-icon {
    fill: var(--purple);
}

.menu__small_screen .burger_menu__close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 15px;
    right: 15px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu__small_screen .burger_menu__close:hover {
    background: var(--grey-2);
}

.menu__small_screen .burger_menu__close-icon {
    width: 15px;
    height: 15px;
    fill: var(--purple);
}

.menu__text {
    font-family: var(--f700-nav);
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--blue);
    text-align: center;
    margin-bottom: 18px;
}

.header__img,
.footer__img {
    width: 100%;
    display: block
}

.header__img2 {
    display: none;
    width: 100%
}

.main {
    background: var(--blue);
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden
}

.exchange-payment-back {
    margin-bottom: 220px !important;
}

.main__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 2
}

.main__text {
    /* max-width: 410px; */
    max-width: 450px;
    width: 100%;
    padding-top: 32px
}

.main__logo {
    margin-bottom: 35px
}

.main__logo_2 {
    display: none
}

/* .main__logo_img {
   
    object-fit: cover
} */
.main__logo_img {
    /* width: 147px; */
    width: 100px;
    padding-top: 40px;
    object-fit: cover;
}

.main__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 40px;
    line-height: 44.6px;
    color: var(--white);
    margin-bottom: 23px
}

.main__description, .privacy_policy {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--white);
    text-align: justify;
}


.privacy_policy {
    text-align: left
}




.privacy_policy_url {
    font-family: var(--f400-secondary);
    font-weight: 800;
    color: var(--white);
    cursor: pointer;
    text-decoration: underline;
}

.privacy_policy_url:hover {
    font-family: var(--f400-secondary);
    font-weight: 800;
    color: var(--white);
    cursor: pointer;
    text-decoration: underline;

}

.main__form {
    max-width: 400px;
    width: 100%;
    padding: 45px 50px 50px 50px;
    border-radius: 6px;
    background: var(--white)
}

.main__bg-logo {
    fill: var(--white);
    position: absolute;
    top: -25%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 150%;
    width: 150%;
    opacity: 1
}

.form__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: var(--blue);
    margin-bottom: 30px
}

.form__span {
    display: block
}

.form__label {
    position: relative;
    margin-bottom: 20px;
    width: 100%
}

.form__inp {
    padding: 18px 25px;
    background: var(--main-input-bg);
    width: 100%;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    line-height: 19.2px;
    color: var(--purple)
}

.form__inp::-webkit-outer-spin-button,
.form__inp::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.form__inp::-webkit-input-placeholder {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px
}

.form__inp::-moz-placeholder {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px
}

.form__inp:-ms-input-placeholder {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px
}

.form__inp::-ms-input-placeholder {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px
}

.form__inp::placeholder {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px
}

.form__inp:focus {
    outline: 2px solid var(--blue)
}

.form__info {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 20px;
    height: 20px;
    fill: transparent;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2
}

.errorMsg {
    color: var(--red)
}

.form__info_hover {
    position: absolute;
    top: -65%;
    right: 0;
    background: var(--purple);
    color: var(--white);
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    /* opacity: 0; */
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.form__info_hover::before,
.form__info_hover::after {
    content: '';
    position: absolute;
    right: 25px;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid var(--purple)
}

.form__info_hover::after {
    border-top: 10px solid var(--purple);
    bottom: -19px
}

.form__btn {
    width: 100%;
    background: var(--blue);
    border: none;
    border-radius: 6px;
    color: var(--white);
    font-family: var(--f700-btn);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding-top: 20px;
    padding-bottom: 21px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    -webkit-transition: background 0.5s;
    -o-transition: background 0.5s;
    transition: background 0.5s
}

.form__btn__disabled {
    cursor: not-allowed;
    background: var(--grey);
    color: #473E9E;
}

.form__btn:hover {
    color: var(--white);
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow)
}

.form__valid_num,
.form__valid_email {
    color: var(--red);
    font-family: var(--f400-secondary);
    font-weight: 400;
    padding: 3px 10px;
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow);
    position: absolute;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    z-index: 10;
    background: var(--white);
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    /* opacity: 0; */
    text-transform: capitalize;
    display: inline-block;
    left: 0;
    top: 100%;
}

.form__valid_num.active,
.form__valid_email.active {
    opacity: 1
}

.row__title {
    position: relative;
    margin-bottom: 20px
}

.title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: var(--blue);
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0
}

.btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 80px;

}

.shop-more-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: start !important;
    padding-bottom: 0px !important;
    margin-bottom: 280px !important;


}

.btn {
    width: 100%;
    text-align: center;
    padding-top: 21px;
    padding-bottom: 20px;
    font-family: var(--f700-btn);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase
}

.btn__back {
    border: 2px solid var(--purple);
    color: var(--purple)
}

.btn__back:hover {
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow);
    color: var(--purple)
}

.btn__continue {
    background: var(--blue);
    color: var(--white);
    position: relative;
}

.btn__continue:hover {
    color: var(--white);
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow)
}

.btn__small_screen {
    width: 30px;
    position: absolute;
    top: 3px;
    left: 0;
    display: none
}

.btn__icon {
    width: 12px;
    height: 20px;
    fill: var(--blue)
}

.button:disabled {
    background: var(--light-purple);
    pointer-events: auto;
    opacity: 1;
}

.button:disabled:hover {
    color: var(--white);
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}


/* .items {
    padding-top: 96px;
    padding-bottom: 100px;
    background: var(--bg-other-main)
} */

/* .items__content {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
} */

/* .items__left {
    flex: 1 1 0;
    width: 100%;
} */

/* .items__left_wrapper {
    margin: -15px;
    display: flex;
    flex-wrap: wrap;
} */

/* .items__right {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 6px;
    background: var(--reason-input-border);
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    flex: 0.5 1 0;
    min-width: 400px;
    max-width: 450px;
    margin-left: 30px;
    display: none;
} */

/* .items__right_title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: var(--blue);
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1
} */

/* .items__carousel {
    margin-bottom: 24px;
    padding-top: 50px
}

.items__carousel_indicators {
    top: 5px;
    right: 30px;
    left: auto;
    bottom: auto;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0
}

.items__carousel_indicators [data-bs-target].active {
    background: var(--purple)
}

.items__carousel_indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-top: none;
    border-bottom: none;
    border: 2px solid var(--purple);
    background: transparent;
    opacity: 1
}

.items__right_wrapper {
    padding-left: 30px;
    padding-right: 30px;
}

.items__right_btn {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    color: var(--purple);
    font-family: var(--f700-btn);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    border: 2px solid var(--purple);
    border-radius: 6px;
    background: transparent;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s
}

.items__right_btn:hover {
    background: var(--purple);
    color: var(--white)
}

.carousel__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px
}

.carousel__card {
    width: 50%
}

.carousel__card:first-child {
    margin-right: 30px
}

.carousel__card .card__pic {
    background: var(--white);
    border-radius: 6px;
    max-width: 100%;
    min-height: 180px;
    margin-right: 0;
    margin-bottom: 19px;
    padding: 20px
}

.carousel__card .card__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px
} */

/* .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    border: 3px solid transparent;
    padding: 30px 30px 40px 30px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    cursor: pointer;
    max-width: calc(50% - 30px);
    width: 100%;
    margin: 15px;
} */

/* .card__target {
    width: 35px;
    height: 35px;
    background: var(--blue);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    position: absolute;
    top: -3px;
    left: -3px;
    display: none
} */

/* .card.active .card__target {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
} */

/* .card.active {
    border: 3px solid var(--blue)
} */

/* .card__icon {
    fill: var(--white);
    width: 17px;
    height: 13px
}

.card__pic {
    max-width: 140px;
    width: 100%;
    height: 140px;
    margin-right: 20px
}

.card__img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.card__info {
    padding-top: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
} */

/* .card__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--blue);
    margin-bottom: 8px
}

.card__br {
    display: block
}

.card__new_price {
    font-family: var(--f800-secondary);
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: var(--blue)
}

.card__old_price {
    font-family: var(--f400-secondary);
    font-weight: 400;
    color: var(--purple);
    font-size: 16px;
    line-height: 24px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative
}

.card__old_price::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--purple);
    top: 60%;
    left: 0
} */
/* 

@media (max-width: 1399px) {

    .items__right {
        flex: 1 1 0;
    }

    .items__content.change .card {
        max-width: calc(50% - 30px);
    }

    .card {
        max-width: none;
    }
}

@media (max-width: 991px) {
    .items__content {
        align-items: center;
    }

    .items__left_wrapper {
        margin-bottom: 30px;
    }

    .items {
        padding-top: 45px
    }

    .items__content {
        flex-direction: column;
    }

    .items__right {
        margin-left: 0;
    }

    .card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        max-width: calc(50% - 30px);
    }

    .card__title {
        text-align: center
    }

    .card__info {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .card__pic {
        margin-right: 0;
        margin-bottom: 26px
    }
}

@media (max-width: 575px) {

    .card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 30px 18px 24px 17px
    }

    .card__target {
        width: 25px;
        height: 25px
    }

    .card__icon {
        fill: var(--white);
        width: 14px;
        height: 10px
    }

    .card__title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 9px
    }

    .card__old_price {
        display: none
    }

    .card__new_price {
        font-size: 16px;
        line-height: 24px
    }

    .card:not(.show) {
        display: none
    }

    .items__right {
        max-width: none;
        min-width: auto;
        width: 100%;
    }
}

@media (max-width: 374px) {
    .col-ltl-12 {
        width: 75%
    }

} */

.carousel__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px
}

.reason {
    padding-top: 30px;
    padding-bottom: 100px;
    background: var(--bg-other-main)
}

.reason__label {
    width: 100%;
    padding: 24px 30px;
    background: var(--reason-input-bg);
    border-radius: 6px;
    border: 1px solid var(--reason-input-border);
    font-family: var(--f500-secondary);
    font-weight: 500;
    color: var(--blue);
    font-size: 18px;
    line-height: 21.6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer
}

.reason__label.active {
    border: 2px solid var(--blue);
    padding: 23px 29px
}

.reason__input {
    display: none
}

.reason__input_circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--reason-input-circle);
    position: relative
}

.reason__input_circle::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: transparent
}

.reason__label.active .reason__input_circle {
    border: 2px solid var(--blue)
}

.reason__label.active .reason__input_circle::after {
    background: var(--blue)
}

.other {
    background: var(--reason-input-border);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 6px;
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple);
    margin-bottom: 70px
}

.other__textarea {
    width: 100%;
    background: transparent;
    border: none;
    margin-top: 11px;
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--blue);
    min-height: 114px;
    padding-top: 0;
    resize: none;
    display: none
}

.other__textarea.show {
    display: block
}

.other__textarea::-webkit-input-placeholder {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple)
}

.other__textarea::-moz-placeholder {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple)
}

.other__textarea:-ms-input-placeholder {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple)
}

.other__textarea::-ms-input-placeholder {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple)
}

.other__textarea::placeholder {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--purple)
}

.other__textarea:focus {
    outline: none
}

.other__close {
    fill: var(--purple);
    width: 16px;
    height: 16px;
    cursor: pointer;
    visibility: hidden
}

.other__close.show {
    visibility: visible
}

.choose {
    padding-top: 20px;
    padding-bottom: 0px;
    background: var(--bg-other-main)
}

.choose__card {
    width: 100%;
    padding: 24px 55px 24px 30px;
    background: var(--reason-input-bg);
    border-radius: 6px;
    border: 1px solid var(--reason-input-border);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* margin-bottom: 30px; */
    margin: 0 15px 30px;
    position: relative;
    cursor: pointer
}

.choose__card.active {
    border: 2px solid var(--blue);
    padding: 23px 54px 23px 29px
}

.choose__desc {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    color: var(--blue)
}

.choose__label {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.choose__input {
    display: none
}

.choose__input_circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--reason-input-circle);
    position: relative;
    display: inline-block
}

.choose__input_circle::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: transparent
}

.choose__card.active {
    border: 2px solid var(--blue)
}

.choose__card.active .choose__input_circle::after {
    background: var(--blue)
}

.choose__row {
    margin-bottom: 40px
}

.photo {
    padding-top: 20px;
    padding-bottom: 100px;
    background: var(--bg-other-main)
}

.photo__word {
    color: var(--red)
}

.photo__empty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed var(--blue);
    cursor: pointer;
    height: 100%
}

.photo__card {
    height: 270px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px
}

/* .photo__card_add {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 6px;
    -o-object-position: center;
    object-position: center
} */
.photo__card_add {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
    object-position: center;
    background: #fff;
    /* padding: 5px; */
    /* height: 200px; */
}

.photo__card_delete {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    border-radius: 5px;
    right: 25px;
    top: 10px;
    background: var(--white);
    cursor: pointer
}

.photo__card-icon {
    width: 13px;
    height: 13px;
    fill: var(--red)
}

.photo__row {
    margin-bottom: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.photo__icon {
    width: 70px;
    height: 59px;
    fill: var(--reason-input-circle)
}

.photo__load {
    height: 100%;
    width: calc(100% - 20px);
    padding-top: 60px;
    border: 1px dashed var(--blue);
    display: block;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    background: var(--white)
}

.photo__load_text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 60px;
    text-align: center;
    color: var(--blue);
    margin-bottom: 23px
}

.photo__load_line {
    width: 50%;
    height: 5px;
    background: var(--loading-bg);
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    margin: 0 auto
}

.photo__load_progress {
    width: 0%;
    height: 5px;
    border-radius: 2px;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    background: var(--blue);
    position: absolute;
    top: 0;
    left: 0
}

.photo__add {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    border: 2px solid var(--purple);
    border-radius: 6px
}

.photo__plus {
    width: 20px;
    height: 20px;
    fill: var(--purple)
}

.photo__more {
    color: var(--purple);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px
}

.photo__modal {
    width: 90%;
    border: 2px solid var(--blue);
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow);
    border-radius: 6px;
    padding: 30px;
    position: fixed;
    background: var(--white);
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0);
    -ms-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: 2
}


.photo__modal:target {
    -webkit-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
    pointer-events: auto;

}

.photo__modal.active {
    -webkit-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1)
}

.photo__table {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(163px, 1fr))
}

.photo__table_item {
    max-width: 270px;
    height: 163px;
    cursor: pointer;
    position: relative
}

.photo__table_img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.photo__modal_text {
    position: relative;
    margin-bottom: 40px
}

.photo__modal_close {
    fill: var(--black);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    cursor: pointer
}

.photo__modal_title {
    font-family: var(--f700-photo-title);
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: var(--black);
    text-align: center
}

.photo__table_choose {
    width: 35px;
    height: 35px;
    background: var(--white);
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom: 10px
}

.photo__table_img.active+.photo__table_choose {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.photo__table_icon {
    width: 13px;
    height: 13px;
    fill: var(--blue)
}

.photo__btn {
    margin-bottom: 0
}



.product__top {
    padding-top: 61px;
    padding-bottom: 70px;
    background: var(--bg-other-main)
}

.product__tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.product__card {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.product__screen {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative
}

.product__item {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: var(--blue);
    text-transform: uppercase;
    width: 184px;
    padding-top: 13px;
    padding-bottom: 13px;
    cursor: pointer
}

.product__item.active {
    background: var(--white);
    border-radius: 10px 10px 0 0
}

.product__scoreboard {
    position: relative;
    display: none;
    padding: 50px 33px 41px;
    background: var(--white);
    border-radius: 0 0 10px 10px;
    height: 100%
}

.product__scoreboard.visible {
    display: block
}

.product__panel {
    position: absolute;
    top: 50%;
    left: 33px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.product__panel-item {
    padding: 8px 10px;
    width: 75px;
    height: 80px;
    border-radius: 6px;
    border: 2px solid transparent;
    cursor: pointer
}

.product__panel-item.active {
    border: 2px solid var(--yellow)
}

.product__panel-item:not(:last-child) {
    margin-bottom: 19px
}

.product__panel-img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.product__target {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product__target-img {
    width: 300px;
    height: 350px;
    -o-object-fit: contain;
    object-fit: contain
}

.product__empty {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: var(--blue);
    text-transform: uppercase;
    text-align: center
}

.product__card .product__info-logo {
    display: none
}

.product__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 46px;
    position: relative;
    padding-left: 46px
}

.product__share {
    width: 18px;
    height: 20px;
    fill: none;
    position: absolute;
    top: -20px;
    cursor: pointer
}

.product__info-link {
    width: 170px;
    margin-bottom: 25px
}

.product__info-logo {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.product__info-title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: var(--blue);
    margin-bottom: 15px;
    width: 80%
}

.product__rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 17px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product__stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 14px
}

.product__star {
    width: 22px;
    height: 22px;
    fill: none;
    stroke: var(--yellow)
}

.product__star.like {
    fill: var(--yellow)
}

.product__rating_text {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    vertical-align: top;
    color: var(--yellow)
}

.product__price {
    margin-bottom: 15px
}

.product__price-new {
    font-family: var(--f800-secondary);
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    color: var(--red);
    margin-right: 9px
}

.product__price-old {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: var(--blue);
    margin-right: 9px;
    position: relative
}

.product__price-old::after {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--blue);
    position: absolute;
    top: 60%;
    left: 0
}

.product__benefits-icons,
.product__benefits-desc {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.product__benefits-icons {
    margin-bottom: 14px
}

.product__benefits-desc {
    margin-bottom: 25px
}

.product__benefits-pic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--blue)
}

.product__benefits-icon {
    width: 40px;
    height: 40px
}

.product__benefits-title {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--blue);
    margin-bottom: 0
}

.product__benefits-text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--purple)
}

.product__buy-btn {
    background: var(--red);
    border: none;
    border-radius: 6px;
    padding-top: 21px;
    padding-bottom: 20px;
    font-family: var(--f700-btn);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    font-size: 14px;
    line-height: 14px;
    overflow: hidden;
    position: relative
}

.product__buy-btn:hover {
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow)
}

.product__bottom {
    padding-top: 55px;
    padding-bottom: 107px;
    background: var(--white)
}

.product__bottom-title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 21px;
    color: var(--blue)
}

.product__bottom-tabs {
    margin-bottom: 33px
}

.product__bottom-btn {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 16px;
    color: var(--purple);
    line-height: 35px;
    width: 200px;
    display: inline-block;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding-bottom: 9px
}

.product__bottom-btn::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% + 4px);
    background: var(--light-purple);
    bottom: 0;
    left: 0
}

.product__bottom-btn.active {
    color: var(--blue)
}

.active.product__bottom-btn::after {
    color: var(--blue);
    background: var(--blue)
}

.description__title {
    margin-bottom: 12px;
    line-height: 35px
}

.history__title {
    line-height: 20px;
    margin-bottom: 36px;
    text-align: center
}

.product__bottom-text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--purple)
}

.product__bottom-text:not(:last-child) {
    margin-bottom: 30px
}

.product__history {
    margin-left: 46px;
    border-radius: 10px;
    background: var(--bg-other-main);
    padding: 38px 35px 40px
}

.product__history-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.product__history-item:not(:last-child) {
    margin-bottom: 40px
}

.product__history-item:not(:last-child)::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 40px;
    background: var(--white);
    bottom: -40px;
    left: 35px
}

.product__history-date {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    text-align: center;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--blue);
    margin-right: 20px
}

.product__history-info {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--blue)
}

.product__history-day {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: var(--purple)
}

.product__bottom-tabs {
    display: none
}

.product__bottom .product__buy-btn {
    display: none
}



.p14,
.p17 {
    padding-top: 98px;
    padding-bottom: 100px;
    background: var(--bg-other-main)
}



/* .p12 .choose__card, */
.p14 .choose__card {
    max-width: 345px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 36px 40px 40px;
    background: var(--reason-input-bg);
    border-radius: 6px;
    border: 1px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    /* margin: 0 25px 30px 25px; */
    /* min-height: 405px; */
    position: relative
}

.choose__list {
    /* margin-bottom: 35px */
    margin-bottom: 70px
}

.choose__item {
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative
}

.choose__item_text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--purple);
    display: inline-block;
    /* padding-left: 49px */
}

.choose__input_circle.big {
    width: 30px;
    height: 30px
}

.choose__input_circle.big::after {
    width: 20px;
    height: 20px
}


/* .p12 .choose__card.active, */
.p14 .choose__card.active {
    border: 2px solid var(--blue);
    padding: 35px 39px 39px 39px
}

.choose__sign {
    width: 35px;
    height: 35px;
    margin-right: 15px;
    position: absolute
}

.choose__icon {
    width: 30px;
    height: 30px;
    fill: var(--purple)
}

.icon__return {
    fill: none;
    stroke: var(--purple)
}

.choose__card_title {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--blue)
}



/* .p12 .choose__card_title, */
.p14 .choose__card_title {
    margin-bottom: 33px
}



.p8 .choose__card {
    padding: 13px 57px 13px 30px;
    min-height: 70px;
    height: 100%;
    margin-bottom: 0
}

.p8 .choose__card.active {
    border: 2px solid var(--blue);
    padding: 12px 56px 12px 29px
}

.p8 .choose__span {
    color: var(--purple)
}

.p9__title {
    margin-bottom: 9px
}

.description {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--purple);
    text-align: center
}

.description__warning {
    color: var(--red)
}

.p9__card {
    border-radius: 10px;
    background: var(--white);
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.circle {
    min-width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px
}

.circle__text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: var(--white)
}

.circle__icon {
    width: 40px;
    height: 40px;
    fill: var(--white)
}

.p9__card-text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--blue)
}

.btn__desc {
    margin-bottom: 24px
}

/* .p12 .choose__card, */
.p14 .choose__card {
    height: auto
}

/* .p12 .choose__card_title, */
.p14 .choose__card_title {
    margin-bottom: 5px;
    height: 100%
}

.choose__text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--purple)
}

/* .p12 .choose__text, */
.p14 .choose__text {
    margin-bottom: 32px
}

/* .p12 .choose__text {
    position: relative;
    padding-left: 30px;
    height: 100%
} */

/* .p12 .choose__card {
    max-width: 350px
} */

/* .p12 .choose__card_title {
    height: auto;
    margin-bottom: 31px
} */

/* .p12__icon {
    fill: var(--purple);
    width: 15px;
    height: 11px;
    margin-right: 15px;
    position: absolute;
    top: 10px;
    left: 0
} */


.p18__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    height: 100%
}


.p18__content {
    width: 50%;
    height: 100%
}


.p18__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 370px;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 16px;
    position: relative;
    z-index: 1
}

.p18__left {
    max-width: 400px
}


.p18__title {
    text-align: left;
    padding-left: 0;
    padding-right: 0
}

.rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 14px
}

.star {
    width: 22px;
    height: 22px;
    fill: none;
    stroke: var(--yellow);
    cursor: pointer;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    -o-transition: transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s
}

.star.like {
    fill: var(--yellow)
}

.star.like-down {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.rating_text {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    color: var(--yellow)
}


.p18__desc,
.p19__desc {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--purple);
    margin-bottom: 62px
}


.p18__right {
    background: var(--purple);
    border-radius: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 50px;
    overflow: hidden;
    max-width: 370px;
    width: 100%;
    height: 360px;
    z-index: 1
}






.p17 {
    padding-top: 45px
}

.p17__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 40px
}

.p17 .choose__card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 48%;
    padding: 36px 40px 30px 90px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.p17 .choose__card.active {
    padding: 35px 39px 29px 89px
}

.p17 .choose__text {
    margin-bottom: 0
}

.p17 .choose__label {
    top: 60px;
    left: 40px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.p18__link,
.p23__link {
    color: inherit;
    border-bottom: 1px solid var(--purple)
}

.p18__link:hover,
.p23__link:hover {
    color: inherit
}

.p18__desc {
    margin-bottom: 35px
}

.p18__desc:nth-of-type(2) {
    margin-bottom: 0
}

.p18__right {
    border-radius: 10px;
    background: var(--white);
    padding: 40px;
    overflow: visible;
    height: auto
}

.p18__payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 37px
}

.p18__payment_circle {
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
    margin-top: 10px
}

.p18__payment_icon {
    width: 20px;
    height: 13px;
    fill: var(--white)
}

.payment_list {
    width: 100%
}

.payment_item {
    font-size: 15px;
    line-height: 20px;
    color: var(--blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px
}

.p18__payment-line {
    height: 1px;
    background: var(--purple-opacity-30);
    margin-top: 13px;
    margin-bottom: 13px
}

.payment_item:last-child {
    margin-bottom: 0
}

.payment_property {
    font-family: var(--f700-secondary);
    font-weight: 700;
    margin-right: 5px
}

.payment_value {
    font-family: var(--f400-secondary);
    font-weight: 400
}

.p18__payment_btn {
    border: 2px solid var(--blue);
    border-radius: 6px;
    background: transparent;
    width: 100%;
    padding-top: 21px;
    padding-bottom: 21px;
    text-align: center;
    font-family: var(--f700-btn);
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: var(--blue);
    text-transform: uppercase;
    margin-bottom: 20px
}

.p18__payment_btn:hover {
    border: 2px solid var(--purple);
    background: var(--purple);
    color: var(--white)
}

.p19__row {
    margin-bottom: 60px
}

.p19__title {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    margin-bottom: 6px
}

.p19 .btn__continue {
    word-spacing: 5px
}

.p19__desc {
    max-width: 370px;
    width: 100%;
    margin-bottom: 32px
}

.location__card {
    border-radius: 6px;
    background: var(--white);
    border: 1px solid var(--reason-input-border);
    padding: 15px;
    max-width: 345px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px
}

.location__card:last-child {
    margin-bottom: 0
}

.location__title {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: var(--blue)
}

.location__desc {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--purple)
}

.location__pointer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.location__pic {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--blue);
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 9px
}

.location__icon {
    width: 15px;
    height: 15px;
    fill: none;
    stroke: var(--white);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.location__distance {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--purple)
}

.p19__qr-img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.p16__title {
    margin-bottom: 9px
}

.p16__desc {
    max-width: 470px;
    width: 100%;
    margin-right: auto;
    margin-left: auto
}

.p16__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 57px;
    height: 100%
}

.p16__content {
    width: 50%;
    height: auto
}

.p16__left {
    padding-top: 65px;
    padding-bottom: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background: var(--white);
    position: relative;
    z-index: 1;
    width: 100%
}

.p16__picture {
    max-width: 350px;
    width: 100%;
    max-height: 350px;
    height: 100%
}

.p16__img {
    width: 100%
}

.p16__right {
    padding: 41px 59px 55px;
    background: var(--purple-opacity-20);
    border-radius: 0 10px 10px 0;
    position: relative;
    height: 100%;
    width: 100%
}

.p16__right::before {
    content: '';
    position: absolute;
    display: block;
    background: var(--purple-opacity-20);
    width: 20%;
    height: 100%;
    right: 100%;
    top: 0
}

.info__list {
    margin-bottom: 7px
}

.info__item {
    font-size: 18px;
    line-height: 20px;
    color: var(--blue);
    padding-top: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid var(--purple-opacity-30)
}

.info__item:last-child {
    border-bottom: none
}

.info__property {
    font-family: var(--f700-secondary);
    font-weight: 700;
    margin-right: 5px
}

.info__value {
    font-family: var(--f400-secondary);
    font-weight: 400
}

.p16__offer {
    max-width: 300px;
    width: 100%;
    text-align: left
}

.p20 .row__title,
.p21 .row__title {
    margin-bottom: 30px
}

.p20__title,
.p21__title,
.p22__title {
    text-align: left;
    font-size: 30px;
    line-height: 35px;
    padding-left: 0
}

.exchange__wrapper {
    width: 250px;


}

.exchange__content {
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 40px;
    background: var(--white)
}

.p20__choose {
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid var(--grey);
    padding: 30px
}

.p20__choose:last-child {
    border-bottom: none
}

.p20__choose_desc {
    padding-left: 38px
}

.p20__label {
    right: auto
}

.p20__choose.active {
    border: none;
    border-bottom: 1px solid var(--grey);
    padding: 30px
}

.p20__choose.active:last-child {
    border-bottom: none
}

.exchange__card {
    border-radius: 10px;
    max-height: 450px;
    height: 100%;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 30px
}

.exchange__card_status {
    min-width: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
    padding: 5px 10px;
    text-align: center;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: var(--white);
    text-transform: uppercase;
    position: absolute;
    top: 20px;
    left: 20px
}

.status__new {
    background: var(--red)
}

.status__old {
    background: var(--purple)
}

.exchange__picture {
    max-width: 250px;
    max-height: 250px;
    height: 100%;
    width: 100%;
    margin-bottom: 25px
}

.exchange__img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.exchange__card_desc {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--blue);
    margin-bottom: 7px;
    text-align: center;
    max-height: 75px;
    overflow: hidden
}

.exchange__card_info {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    color: var(--purple);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

/* .exchange__card_separator {
    margin-left: 5px;
    margin-right: 5px
} */

.p21__content {
    padding: 28px 30px 26px
}

.p21__payment_list {
    padding-bottom: 27px;
    /* border-bottom: 1px solid var(--grey); */
    margin-bottom: 25px
}

.p21__methods_pay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.p21__methods_card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.p21__methods_icon {
    width: 35px;
    height: 35px;
    fill: var(--light-blue);
    margin-bottom: 10px
}

.upi_qr {
    fill: var(--white);
    stroke: var(--light-blue)
}

.p21__methods_desc {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--blue)
}

.p22__wrapper {
    max-width: 340px;
    width: 100%
}

.p22__inner {
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 40px
}

.bank_card {
    padding: 20px 40px 30px 30px;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-2)
}

.bank_card__block {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    color: var(--purple);
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.block__number {
    width: 100%;
    margin-bottom: 20px
}

.block__date {
    max-width: 120px;
    width: 100%;
    margin-right: 30px
}

.block__cvv {
    max-width: 120px;
    width: 100%;
    position: relative
}

.bank_card__input {
    border-radius: 3px;
    border: 1px solid var(--grey-2);
    padding: 10px 20px;
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--blue)
}

.bank_card__input:focus {
    outline: none;
    border: 1px solid var(--purple)
}

.bank_card__date::-webkit-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--purple)
}

.bank_card__date::-moz-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--purple)
}

.bank_card__date:-ms-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--purple)
}

.bank_card__date::-ms-input-placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--purple)
}

.bank_card__date::placeholder {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--purple)
}

.bank_card__icon {
    width: 18px;
    height: 18px;
    fill: none;
    stroke: var(--purple);
    position: absolute;
    bottom: 15px;
    right: 21px;
    cursor: pointer;
    z-index: 10
}

.p22 .btns {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.p22 .choose__card {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    padding: 24px 30px 24px 20px
}

.p22 .choose__card:first-child {
    border-bottom: 1px solid var(--grey-2)
}

.p22 .choose__card_title {
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    padding-left: 38px;
    margin-bottom: 0
}

.p22 .choose__label {
    right: auto
}

.p23__wrapper {
    margin-bottom: 53px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.p23 .row__title {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-bottom: 15px
}

.p23__title {
    line-height: 50px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 11px
}

.p23__desc {
    max-width: 300px;
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 15px;
    color: var(--purple)
}

.option__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.option__wrapper {
    max-width: 300px;
    width: 100%
}

.option__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.option .row__title {
    margin-bottom: 10px;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.option__title {
    font-size: 30px;
    line-height: 45px;
    padding-left: 0;
    padding-right: 0;
    text-align: left
}

.option__desc {
    display: none
}

.option__panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 28px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.option__tab {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 14px 5px;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.option__tab:hover {
    color: var(--purple)
}

.swiper-slide-thumb-active .option__tab {
    padding: 14px 5px 13px;
    color: var(--blue);
    border-bottom: 2px solid var(--blue)
}

.thumbSwiper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.thumbSwiper .swiper-slide {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.option__subtitle {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--blue);
    margin-bottom: 17px
}

.option__colors {
    margin-bottom: 17px
}

.option__choise_label {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;

}



.option__name {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--blue);
    margin-bottom: 10px;
    display: inline-block
}

.option__choise_radio {
    display: none
}

.label__color {
    width: 28px;
    height: 28px
}

.option__choise_circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid var(--white);
    position: relative;
    display: inline-block;
    z-index: 2
}

.option__choise_ring {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0
}

.option__choise_radio:checked+.label__color .option__choise_ring {
    opacity: 1
}

.option__choise_radio:checked+.label__size .option__choise_square {
    border: 2px solid var(--blue);
    color: var(--blue);
    padding: 0 10px;
    background: var(--white) !important;
}

.option__choise_square {
    min-width: 40px;
    height: 40px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid var(--purple);
    color: var(--purple);
    font-family: var(--f600-secondary);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
}

.option__size {
    margin-bottom: 26px
}

.option__items_content {
    margin-bottom: 10px
}

.option__items_card {
    border-radius: 10px;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px
}

.option__items_card:last-child {
    margin-bottom: 0
}

.option__items_picture {
    min-width: 60px;
    height: 60px;
    margin-right: 6px
}

.option__items_img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.option__items_title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: var(--blue);
    margin-bottom: 0
}

.option__price_new {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--red);
    margin-right: 8px
}

.option__price_old {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--purple);
    position: relative
}

.option__price_old:after {
    content: '';
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--purple)
}

.option .btn__continue:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.option__screen {
    /* max-width: 500px; */
    /* padding: 50px 100px; */
    width: 100%;
    /* min-height: 415px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    margin-right: 30px;
    padding-right: 150px;
    max-width: 400px;
    width: 400px;
    min-height: auto;
    /* height: 365px; */
    height: 430px;
    padding: 30px 130px 35px 35px;
}

.option__list {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.option__list_item {
    padding: 8px 10px;
    width: 75px;
    height: 80px;
    border-radius: 6px;
    border: 2px solid transparent;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.option__list_item.active {
    border: 2px solid var(--yellow)
}

.option__list_item:not(:last-child) {
    margin-bottom: 19px
}

.option__list_img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.option__target {
    width: 300px;
    height: 300px
}

.option__target_img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.advert {
    width: 260px;
    height: 360px;
    border-radius: 10px;
    background: var(--blue);
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden
}

.advert__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: var(--white);
    text-align: center;
    position: relative;
    z-index: 2
}

.advert__item {
    min-width: 100px;
    min-height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid var(--white);
    background: var(--blue);
    position: relative;
    z-index: 3;
    padding: 10px;
}

.advert__price {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: var(--white)
}

.advert__size {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--white)
}

.advert__bg_img {
    position: absolute;
    opacity: 0.1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    -ms-transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    z-index: 1
}

.advert__bonus {
    position: relative;
    z-index: 2;
    border-radius: 6px;
    background: var(--red);
    padding: 14px;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--white)
}

.p7__panel {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 2px solid var(--purple);
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden
}

.p7__tabs {
    min-width: 160px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    display: inline-block;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px
}

#colorOrSize {
    color: var(--purple);
    background: var(--white)
}

#otherItems {
    color: var(--white);
    background: var(--purple)
}

.p7__row {
    margin-bottom: 32px
}

.p7__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.p7 .row__title {
    margin-bottom: 0
}

.p7__title {
    text-align: left;
    padding-left: 0
}

.p7__desc {
    display: none
}

.p7Swiper {
    padding-top: 55px
}

.p7Swiper .swiper-slide {
    height: calc((100% - 30px) / 2)
}

.p7__swiper-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--main-input-bg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.p7__swiper-icon {
    width: 9px;
    height: 15px;
    fill: var(--blue)
}

.p7Swiper .swiper-button-next {
    right: 0;
    top: 0;
    margin-top: 0
}

.p7Swiper .swiper-button-prev {
    left: auto;
    right: 60px;
    top: 0;
    margin-top: 0
}

.p7Swiper .swiper-button-next:after,
.p7Swiper .swiper-button-prev:after {
    display: none
}

.p7__content {
    border-radius: 10px;
    background: var(--white);
    position: relative;
    padding: 25px 50px 40px;
    margin-bottom: 43px
}

.p7__card {
    min-width: 160px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 10px
}

.p7__card-picture {
    width: 100%;
    height: 200px;
    margin-bottom: 10px
}

.p7__card-img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.p7__card-desc {
    text-align: left
}

.p7__card-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 20px
}

.p7 .exchange__card_separator {
    display: none
}

.p7__card-more {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
    color: var(--blue)
}

.p7__card-more:hover {
    color: var(--light-blue)
}

.p7__content-title {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: var(--blue);
    position: absolute;
    top: 36px;
    left: 50px;
    z-index: 2
}

.part__page {
    background: var(--blue);
    position: relative;
    overflow: hidden
}

.part__row {
    padding-top: 21px;
    padding-bottom: 21px
}

.part__form {
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background: var(--white);
    padding: 45px 60px 50px;
    position: relative;
    z-index: 5
}

.part__bg-logo {
    position: absolute;
    fill: var(--white);
    height: 145%;
    width: 150%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1
}

.bg-logo__left {
    left: 75%
}

.bg-logo__right {
    right: 75%
}

.part__form-title {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: var(--blue);
    margin-bottom: 20px
}

.part__form-data {
    margin-bottom: 30px
}

.part__form-inp {
    width: 100%;
    border-radius: 5px;
    background: var(--bg-other-main);
    padding: 16px;
    outline: 1px solid var(--grey-2);
    font-family: var(--f500-secondary);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--blue);
    margin-bottom: 20px;
    border: none;
}

.part__form-inp:focus {
    outline: 1px solid var(--blue)
}

.part__form-inp::-webkit-input-placeholder {
    color: var(--purple)
}

.part__form-inp::-moz-placeholder {
    color: var(--purple)
}

.part__form-inp:-ms-input-placeholder {
    color: var(--purple)
}

.part__form-inp::-ms-input-placeholder {
    color: var(--purple)
}

.part__form-inp::placeholder {
    color: var(--purple)
}

.part__form-inp:last-child {
    margin-bottom: 0
}

.part__form-btn {
    width: 100%;
    background: var(--red);
    text-align: center;
    padding-top: 13px;
    padding-bottom: 13px;
    text-transform: uppercase;
    font-family: var(--f900-secondary);
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    letter-spacing: 0.1em
}

.part__form-btn:disabled {
    background: var(--pink)
}

.part__form-label {
    width: 100%;
    position: relative
}

.part__invalid-email {
    color: var(--red);
    font-family: var(--f400-secondary);
    font-weight: 400;
    padding: 5px 10px;
    -webkit-box-shadow: var(--btn-shadow);
    box-shadow: var(--btn-shadow);
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    z-index: 10;
    background: var(--white);
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    opacity: 0;
    text-transform: capitalize
}

.part__invalid-email.active {
    opacity: 1
}


.form__invalid {
    -webkit-animation: infinite 2s warning;
    animation: infinite 2s warning
}

.form__invalid:focus {
    outline: none;
    -webkit-animation: infinite 2s warning;
    animation: infinite 2s warning
}

@-webkit-keyframes warning {
    from {
        -webkit-box-shadow: inset 0 0 10px 2px var(--red);
        box-shadow: inset 0 0 10px 2px var(--red)
    }

    50% {
        -webkit-box-shadow: inset 0 0 5px 1px var(--red);
        box-shadow: inset 0 0 5px 1px var(--red)
    }

    to {
        -webkit-box-shadow: inset 0 0 10px 2px var(--red);
        box-shadow: inset 0 0 10px 2px var(--red)
    }
}

@keyframes warning {
    from {
        -webkit-box-shadow: inset 0 0 10px 2px var(--red);
        box-shadow: inset 0 0 10px 2px var(--red)
    }

    50% {
        -webkit-box-shadow: inset 0 0 5px 1px var(--red);
        box-shadow: inset 0 0 5px 1px var(--red)
    }

    to {
        -webkit-box-shadow: inset 0 0 10px 2px var(--red);
        box-shadow: inset 0 0 10px 2px var(--red)
    }
}

@media (max-width: 1399px) {
    .items__content {
        /* grid-template-columns: repeat(2, 1fr) */
    }

    .items__left {
        /* grid-column: 1 / span 1 */
    }

    .items__left_wrapper {
        /* grid-template-columns: repeat(1, 1fr) */
    }

    .items__right {
        grid-column: 2 / span 1
    }
}

@media (max-width: 1199px) {
    .product__info {
        padding-left: 23px
    }

    .product__history {
        margin-left: 0
    }



    /* .p12 .choose__card, */
    .p14 .choose__card {
        max-width: 300px
    }

    .choose__card_title {
        font-size: 21px;
        line-height: 25px
    }



    .p14 .choose__card_title {
        margin-bottom: 5px
    }

    .choose__list {
        margin-bottom: 20px
    }

    .choose__icon {
        width: 25px;
        height: 25px
    }

    .p8 .choose__row>* {
        margin-bottom: 30px
    }

    /* .p12 .choose__card_title {
        margin-bottom: 22px
    } */

    .p22__wrapper {
        max-width: 100%
    }

    .option .row__title {
        width: 100%;
        margin-bottom: 30px;
        /* margin-bottom: 10px; */


    }

    .option__title {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;

    }

    .option__desc {
        display: block
    }

    .advert {
        display: none
    }

    .p7__desc {
        display: block;
        text-align: left
    }
}

@media (max-width: 991px) {
    .main__logo {
        display: none;
    }

    .main__logo_2 {
        display: block
    }

    .main__bg-logo {
        opacity: 0
    }

    .main__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .main {
        padding-top: 46px;
        padding-bottom: 70px
    }

    .main__text {
        text-align: center;
        margin-bottom: 44px;
        padding-top: 0
    }

    .main__form {
        margin-bottom: 80px
    }

    .items {
        padding-top: 45px
    }

    .row__title {
        margin-bottom: 30px
    }

    .title {
        font-size: 30px;
        line-height: 36px
    }

    .items__content {
        grid-template-columns: repeat(1, 1fr)
    }

    .items__left_wrapper {
        grid-template-columns: repeat(2, 1fr)
    }

    .items__right {
        display: none
    }

    .card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .card__title {
        text-align: center
    }

    .card__info {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .card__pic {
        margin-right: 0;
        margin-bottom: 26px
    }

    .reason {
        padding-top: 45px;
        padding-bottom: 50px
    }

    .reason__label {
        margin-bottom: 15px
    }

    .other {
        margin-bottom: 40px
    }

    .choose {
        padding-top: 45px
    }

    .phone {
        padding-top: 45px
    }

    .product__card {
        padding-bottom: 26px
    }

    .product__top {
        padding-top: 50px;
        padding-bottom: 46px
    }

    .product__info {
        max-width: 400px;
        padding-left: 0;
        margin: 0 auto;
        padding-top: 0
    }

    .product__scoreboard {
        padding-bottom: 79px
    }

    .product__screen .product__info-logo {
        display: inline;
        position: absolute;
        width: 140px;
        bottom: 30px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .product__info .product__info-link {
        display: none
    }

    .product__benefits-desc {
        margin-bottom: 0
    }

    .product__top .product__buy-btn {
        display: none
    }

    .product__bottom .product__buy-btn {
        display: inline-block;
        width: 50%;
        margin-bottom: 12px
    }

    .product__share {
        top: 0;
        right: 0
    }

    .product__bottom {
        padding-top: 15px;
        padding-bottom: 20px
    }

    .product__bottom-title {
        display: none
    }

    .product__bottom-tabs {
        display: block
    }

    .product__bottom-show {
        display: none
    }

    .product__bottom-show.active {
        display: block;
        margin-bottom: 70px
    }

    .product__history {
        padding: 0;
        padding-top: 7px;
        background: var(--white)
    }

    .product__history-date {
        background: var(--bg-other-main)
    }


    /* .p12 .choose__card, */
    .p14 .choose__card {
        max-width: 100%;
        padding: 24px 30px 24px 70px
    }



    /* .p12 .choose__card.active, */
    .p14 .choose__card.active {
        padding: 23px 29px 23px 69px
    }

    .choose__input_circle.big {
        width: 20px;
        height: 20px
    }

    .choose__input_circle.big::after {
        width: 10px;
        height: 10px
    }







    .choose__card.active {
        /* top: 26px; */
        /* left: 24px */
    }

    .p9__row>*:first-child .p9__card {
        padding-top: 30px;
        border-radius: 10px 10px 0 0
    }

    .p9__row>*:last-child .p9__card {
        border-radius: 0 0 10px 10px;
        padding-bottom: 30px
    }

    .p9__card {
        border-radius: 0;
        padding: 15px 30px
    }

    .p14 .choose__card {
        max-width: 75%
    }



    .p17__row {
        margin-bottom: 20px
    }

    .p17 .choose__card {
        max-width: 75%;
        padding: 25px 31px 25px 70px
    }

    .p17 .choose__card.active {
        padding: 24px 30px 24px 69px
    }

    .p17 .choose__label {
        top: 40px;
        left: 30px
    }

    .p17 .choose__text {
        font-size: 16px;
        line-height: 26px
    }

    .p19__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 40px
    }

    .p19__title {
        text-align: center;
        margin-bottom: 4px
    }

    .p19__desc {
        max-width: 250px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 34px
    }

    .location__card {
        margin-right: auto;
        margin-left: auto
    }

    .p16__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .p16__content {
        width: 100%
    }

    .p16__left {
        border-radius: 10px 10px 0 0;
        padding-top: 40px;
        padding-bottom: 40px
    }

    .p16__right {
        border-radius: 0 0 10px 10px
    }

    .p16__right::before {
        display: none
    }

    .info__list {
        margin-bottom: 0
    }

    .btns__text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .p16__offer {
        max-width: 100%;
        text-align: center;
        margin-bottom: 9px
    }

    .exchange__wrapper {
        max-width: 100%;
        width: 100%;
    }

    .p22 .btns {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .p23__wrapper {
        margin-bottom: 28px
    }

    .p23__title {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px
    }

    .p23__desc {
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }

    .p23__rating {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .option__wrapper {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: auto;
        margin-right: auto
    }

    .option__right {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        margin-left: auto;
        margin-right: auto;
        /* margin-bottom: 36px */
    }

    .option__screen {
        margin-right: 0
    }
}

@media (max-width: 767px) {
    .header__img2 {
        display: block
    }

    .header {
        padding: 20px 17px
    }

    .header__img {
        display: none
    }

    .main__text {
        text-align: center
    }

    .other__textarea {
        min-height: 139px;
        margin-top: 21px
    }

    .photo__modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-transform: translateX(0) scale(0);
        -ms-transform: translateX(0) scale(0);
        transform: translateX(0) scale(0);
        border-radius: 0;
        border: none;
        padding: 15px
    }

    .photo__modal.active {
        -webkit-transform: translateX(0) scale(1);
        -ms-transform: translateX(0) scale(1);
        transform: translateX(0) scale(1)
    }

    .photo__table {
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(98px, 1fr))
    }

    .photo__table_item {
        max-width: 98px;
        height: 98px;
        cursor: pointer;
        position: relative
    }

    .photo__card {
        height: 220px
    }

    .photo__add {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 11px 0
    }

    .photo__more {
        margin-right: 10px
    }

    .photo__row {
        margin-bottom: 30px
    }

    .photo__btn {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important
    }

    .product__target-img {
        width: 200px;
        height: 250px;
        -o-object-fit: contain;
        object-fit: contain
    }

    .product__panel-item {
        width: 55px;
        height: 70px;
        padding: 10px 8px
    }

    .product__panel-item:not(:last-child) {
        margin-bottom: 0
    }

    .product__bottom-btn {
        width: 49%
    }

    .btn__small_screen {
        display: inline-block
    }

    .btn__big_screen {
        display: none
    }

    .p17 .choose__card {
        max-width: 100%
    }

    .p18__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .p18__content {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .p18__content:nth-child(2) {
        display: none
    }

    .p18__left {
        padding-top: 0;
        padding-bottom: 0
    }

    .p18__title {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        margin-bottom: 30px
    }

    .p18__desc {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .p18__desc:nth-of-type(2) {
        margin-bottom: 24px
    }

    .p18__rating {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .p18__right {
        max-width: 100%;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 34px;
        padding: 36px
    }

    .p18__buttons {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .p18__payment {
        margin-bottom: 0
    }

    .exchange__card {
        padding: 30px 20px 14px;
        max-height: 100%;
        justify-content: center;
    }

    .exchange__card_status {
        top: 10px;
        left: 10px
    }

    .exchange__card_desc {
        font-size: 14px;
        line-height: 20px
    }

    .exchange__picture {
        margin-top: 48px;
        margin-bottom: 17px;
    }

    .exchange__card_info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        font-style: 14px;
        line-height: 20px
    }

    .exchange__card_separator {
        display: none
    }

    .p20__title,
    .p21__title,
    .p22__title {
        padding-left: 30px;
        text-align: center
    }

    .p20__choose,
    .p20__choose.active {
        padding: 20px
    }

    .p20__choose_desc {
        font-size: 16px
    }

    /* 
    .option__screen {
        padding: 40px;
        max-width: 350px;
        min-height: 200px
    } */

    .option__screen {
        padding: 0;
        max-width: 350px;
        min-height: auto;
        height: auto;
        padding-left: 50px;
        margin-bottom: 30px;
    }

    .option__list_item {
        width: 55px;
        height: 50px
    }

    .option__list {
        right: auto;
        left: 15px
    }

    /* .option__target {
        width: 250px;
        height: 200px
    } */
    .option__target {
        width: auto;
        height: auto;
    }

    .option__target>img {

        height: 250px;
        object-fit: scale-down;
        margin: 30px 0;

    }

    .p7__content-title {
        display: none
    }

    .p7__swiper-btn {
        display: none
    }

    .p7__content {
        padding: 15px 20px 23px
    }

    .p7Swiper {
        padding-top: 0
    }

    .p7__top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .p7__title {
        padding-left: 30px;
        text-align: center
    }

    .p7__desc {
        text-align: center
    }

    .p7 .row__title {
        margin-bottom: 24px
    }

    .p7__panel {
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width: 575px) {
    .main__logo {
        margin-bottom: 25px
    }

    .main__title {
        font-size: 26px;
        line-height: 28.99px;
        margin-bottom: 18px
    }

    .main__description {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    .main__form {
        padding: 34px 40px 40px 40px
    }

    .form__title {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 32px
    }

    .form__label {
        margin-bottom: 21px
    }

    .items__left_wrapper {
        grid-gap: 20px
    }

    .row__title {
        margin-bottom: 30px
    }

    .title {
        font-size: 26px;
        line-height: 36px
    }

    .card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 30px 18px 24px 17px
    }

    .card__target {
        width: 25px;
        height: 25px
    }

    .card__icon {
        fill: var(--white);
        width: 14px;
        height: 10px
    }

    .card__title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 9px
    }

    .card__old-price {
        display: none
    }

    .card__new-price {
        font-size: 16px;
        line-height: 24px
    }

    .col-6 {
        padding-right: 8px;
        padding-left: 8px
    }

    .card:not(.show) {
        display: none
    }

    .photo__load_text {
        width: 100%;
        font-size: 16px;
        line-height: 24px
    }

    .photo__load {
        height: 100%;
        width: calc(100% - 15px);
        padding-top: 60px;
        border: 1px dashed var(--blue);
        display: block;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 10;
        background: var(--white)
    }

    .product__item {
        width: 50%
    }

    .product__scoreboard {
        padding: 40px 22px 79px
    }

    .product__panel {
        left: 22px
    }

    .product__info-title {
        font-size: 26px
    }

    .product__info {
        width: 100%
    }

    .product__bottom .product__buy-btn {
        display: inline-block;
        width: 60%;
        position: fixed;
        bottom: 30px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        margin-bottom: 0;
        z-index: 5;
        -webkit-box-shadow: var(--btn-shadow);
        box-shadow: var(--btn-shadow)
    }

    .product__description {
        height: 300px;
        overflow-y: scroll
    }

    .choose__item_text {
        padding-left: 40px
    }

    .p14 .choose__card {
        max-width: 100%
    }

    .location__card {
        max-width: 100%
    }

    .p16__right {
        padding: 7px 29px 11px
    }

    .info__item {
        font-size: 16px
    }

    .p16__offer {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }

    .btn__desc {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto
    }

    .payment_item {
        font-size: 16px;
        margin-bottom: 7px
    }

    .p21__methods_desc {
        font-size: 14px
    }

    .p22 .choose__card_title {
        font-size: 16px;
        line-height: 19.2px
    }

    .p23__desc {
        font-size: 16px;
        line-height: 24px
    }

    .part__form {
        padding: 40px 23px 62px
    }

    .part__form-title {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 30px
    }

    .btn__continue {
        display: inline-block;
        /* position: fixed; */
        width: calc(100% - var(--bs-gutter-x, .75rem));
        bottom: 20px;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
    }

    .option .btn__continue {
        position: static;
        width: 100%;
        transform: none;
    }

}

@media (max-width: 420px) {
    .photo__card {
        height: 180px
    }

    .photo__one {
        width: 100% !important;
        height: 290px !important
    }

    .photo__load_text {
        width: 50%;
        margin-right: auto;
        margin-left: auto
    }

    .product__target-img {
        width: 150px;
        height: 200px
    }

    .product__bottom .product__buy-btn {
        width: 95%
    }

    .payment_item {
        margin-bottom: 7px;
        font-size: 16px
    }

    .p18__desc {
        font-size: 16px;
        line-height: 24px
    }
}

@media (max-width: 374px) {
    .col-ltl-12 {
        width: 75%
    }

    .items__left_wrapper {
        grid-template-columns: repeat(1, 1fr)
    }

    .photo__card {
        height: 150px
    }

    .product__panel {
        left: 10px
    }
}

.main__bg_logo {
    fill: var(--white);
    position: absolute;
    top: -25%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 150%;
    width: 150%;
    opacity: 1;
}

.btn__back__bg {
    background-color: var(--grey) !important;

}

.info__error {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--red);
    padding: 15px 0px;
    border-radius: 50px;
    background: var(--red-opacity-15);
    margin-top: auto;
    margin-bottom: 20px;
    text-align: center;
    width: 250px;
}

.info__error_policy {
    color: var(--red);
}

.info__error_policy:hover {
    color: var(--red);
}