.photo {
    overflow: hidden !important;
}

.camera-input {
    border: 1px dashed var(--blue);
    padding: 10% 3% 3%;
    border-radius: 5%;
    line-height: 2;
    height: 260px;
    background-color: var(--color-primaryFade);
}

.preview {
    width: 260px;
    height: 260px;
}

.progress-bar-container {
    width: 200px;
    margin: auto;
}

.progress-bar {
    height: 4px;
    background-color: rgba(5, 116, 206, 0.782);
    width: 100%;
    overflow: hidden;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(159, 186, 208);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}