.footer-custom {
    display: block;
    text-align: center;
    padding: 10px 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 999;
}

.footer-store-text {
    text-transform: capitalize;
}