.btn-continue-big-device {

    width: 180px !important;
    margin-left: 4% !important;

}

.btn-continue-big-device,
.btn-back-big-device {
    margin-bottom: 0px !important;
}

.btn-main-big {
    width: 500px !important;
}

.btn-back-big-device {

    width: 170px !important;
    margin-right: 10px;
    margin-left: 5px;

}

.react-tabs__tab {
    display: inline-block;
    border-bottom: 0px solid blue !important;
    border-bottom: none;
    bottom: 0px;
    position: relative;
    list-style: none;
    padding: 10px 22px;
    font-size: 16px;
    cursor: pointer;
}

.react-tabs__tab-list {
    width: 300px;
    border-bottom: 1px solid var(--purple) !important;
    margin-bottom: 30px !important;
}

.react-tabs__tab--selected {
    border: none !important;

    border-bottom: 2px solid var(--blue) !important;
    color: var(--blue) !important;
    background: transparent !important;


}

.mobile_show {
    display: none;
}

.option__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.option__wrapper {
    max-width: 300px;
    width: 100%
}

.option__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.option .row__title {
    margin-bottom: 10px;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.option__title {
    font-size: 30px;
    line-height: 45px;
    padding-left: 0;
    padding-right: 0;
    text-align: left
}

.option__desc {
    display: none
}

.option__panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 28px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.option__tab {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 14px 5px;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.option__tab:hover {
    color: var(--purple)
}

.swiper-slide-thumb-active .option__tab {
    padding: 14px 5px 13px;
    color: var(--blue);
    border-bottom: 2px solid var(--blue)
}

.thumbSwiper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.thumbSwiper .swiper-slide {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.option__subtitle {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: var(--blue);
    margin-bottom: 17px
}

.option__colors {
    margin-bottom: 17px
}

.option__choise_label {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer
}

.option__name {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--blue);
    margin-bottom: 10px;
    display: inline-block
}

.option__choise_radio {
    display: none
}

.label__color {
    width: 28px;
    height: 28px
}

.option__choise_circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid var(--white);
    position: relative;
    display: inline-block;
    z-index: 2
}

.option__choise_ring {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0
}

.option__choise_radio:checked+.label__color .option__choise_ring {
    opacity: 1
}

.option__choise_radio:checked+.label__size .option__choise_square {
    border: 2px solid var(--blue);
    color: var(--blue)
}

.option__choise_square {
    min-width: 40px;
    height: 40px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid var(--purple);
    color: var(--purple);
    font-family: var(--f600-secondary);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}

.option__size {
    margin-bottom: 40px
}

.option__items_content {
    margin-bottom: 40px
}

.option__items_card {
    border-radius: 10px;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px
}

.option__items-card:last-child {
    margin-bottom: 0
}

.option__items_picture {
    min-width: 60px;
    height: 60px;
    margin-right: 6px
}

.option__items_img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.option__items_title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: var(--blue);
    margin-bottom: 0
}

.option__price_new {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--red);
    margin-right: 8px
}

.option__price_old {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--purple);
    position: relative
}

.option__price_old:after {
    content: '';
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--purple)
}

.option .btn__continue:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.option__screen {
    max-width: 500px;
    padding: 50px 100px;
    width: 100%;
    min-height: 415px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    margin-right: 30px
}

.option__list {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.option__list_item {
    padding: 8px 10px;
    width: 75px;
    height: 80px;
    border-radius: 6px;
    border: 2px solid transparent;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.option__list_item.active {
    border: 2px solid var(--yellow)
}

.option__list_item:not(:last-child) {
    margin-bottom: 19px
}

.option__list_img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.option__target {
    width: 300px;
    height: 300px
}

.option__target_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 10px;
}

.option__target_img.show {
    opacity: 1;
}

.advert {
    width: 260px;
    height: 360px;
    border-radius: 10px;
    background: var(--blue);
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden
}

.advert__title {
    font-family: var(--f700-primary);
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: var(--white);
    text-align: center;
    position: relative;
    z-index: 2
}

.advert__item {
    min-width: 100px;
    min-height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid var(--white);
    background: var(--blue);
    position: relative;
    z-index: 3
}

.advert__price {
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: var(--white)
}

.advert__size {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--white)
}

.advert__bg_img {
    position: absolute;
    opacity: 0.1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    -ms-transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    transform: translate(-53%, -50%) rotate(15deg) scale(0.9);
    z-index: 1
}

.advert__bonus {
    position: relative;
    z-index: 2;
    border-radius: 6px;
    background: var(--red);
    padding: 14px;
    font-family: var(--f700-secondary);
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--white)
}



.photo__load {
    height: 100%;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    background: transparent
}

.photo__load.hide {
    z-index: -10;
}

.photo__load_text {
    font-family: var(--f400-secondary);
    font-weight: 400;
    font-size: 16px;
    line-height: 60px;
    text-align: center;
    color: var(--blue);
    margin-bottom: 23px
}

.photo__load_line {
    width: 50%;
    height: 5px;
    background: var(--loading-bg);
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    margin: 0 auto
}

.photo__load_progress {
    width: 0%;
    height: 5px;
    border-radius: 2px;
    background: var(--blue);
    position: absolute;
    top: 0;
    left: 0
}

.photo__load_progress.work {
    width: 100%;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

@media (max-width: 1199px) {

    .option .row__title {
        width: 100%;
        margin-bottom: 30px
    }

    .option__title {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 14px
    }

    .option__desc {
        display: block
    }

    .advert {
        display: none
    }


}

@media (max-width: 991px) {
    .btn-continue-big-device {

        width: 100% !important;
        margin-bottom: 50px !important;

    }

    .btn-main-big {
        width: 100% !important;
    }

    .mobile_hide {
        display: none;
    }

    .mobile_show {
        display: block;
    }

    .option__wrapper {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: auto;
        margin-right: auto
    }

    .option__right {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 36px
    }

    .option__screen {
        margin-right: 0
    }
}

@media (max-width: 767px) {

    .option__screen {
        padding: 40px;
        max-width: 350px;
        min-height: 200px
    }

    .option__list_item {
        width: 55px;
        height: 50px
    }

    .option__list {
        right: auto;
        left: 15px
    }

    .option__target {
        width: 250px;
        height: 200px
    }

}

@media (max-width: 575px) {
    .option .btn__continue {
        position: static;
        width: 100%;
        transform: none;
    }

    .photo__load_text {
        width: 100%;
        font-size: 16px;
        line-height: 24px
    }
}

@media (max-width: 420px) {

    .photo__load_text {
        width: 50%;
        margin-right: auto;
        margin-left: auto
    }

}