.container500 {
    display: flex;
    overflow: hidden !important;
}

.seventy500 {
    width: 70%;
    height: 100vh;
    background: #f1f1f1;
    display: inline-block;
    text-align: center;
}

.content500 {
    margin-top: 25vh;
    font-family: sans-serif;
    transform: scale(2);
    font-size: large;
    margin-left: -10rem;
}

.pink-btn500 {
    border: none;
    outline: none;
    color: white;
    background: #ff4c6b;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
}

.thirty500 {
    width: 30%;
    height: 100vh;
    background: rgb(71, 62, 158);
    display: inline-block;
}

.carbon-img500 {
    position: absolute;
    overflow: hidden;
    width: 30vw;
    top: 15%;
    left: 54.5vw;
}

@media only screen and (max-width: 600px) {
    .carbon-img500 {
        position: absolute;
        overflow: hidden;
        width: 60vw;
        top: 10%;
        left: 21.5vw;
    }

    .content500 {
        position: relative;
        width: 23rem;
        overflow: hidden;
        margin: 23rem 0 0 2rem;
        transform: scale(1.3);
        font-size: inherit;
    }
}