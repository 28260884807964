.brand-logo-text {
    text-transform: uppercase;
    color: #000000
}

.navbar {
    background-color: #ffffff;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    padding: 50px;
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
}

.nav-logo {
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: center;
    margin-right: 2rem;

    padding-top: 0px;
}

.nav-links {
    color: #000000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-family: var(--f700-nav);
    font-size: 15px;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    /* line-height: 40px; */
    /* margin-right: 1rem; */
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: #e8e8e8;
}

.nav-item.active {
    color: #000000;
    /* border: 1px solid #ffdd40; */
}

.nav-icon {
    display: none;
}

@media screen and (max-width: 960px) {

    .nav-container {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding-top: 40px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;

        width: 100%;
        border-top: 1px solid var(--grey-2);
        position: absolute;
        top: 100px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        height: 100vh;
    }

    .nav-menu.active {
        background: #ffffff;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: var(--blue);
        border: none;
    }

    .nav-links {
        /* padding: 1.5rem; */
        width: 100%;
        display: table;
        font-size: 15px;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000000;
    }

    .fa-times-circle:hover {
        color: var(--blue);
    }
}